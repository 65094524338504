/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Sine, TimelineMax, Power1, TweenMax } from 'gsap';
import { SplitText } from '../../utils/SplitText';
import { StateContext } from '../../utils/globalState';

/* Components */
import Header from '../header';
import Background from './Quiz/backgrounds';
import Footer from '../footer';
import Loader from '../loader';

/* Stages */
import Explore from './Quiz/explore';
import Purpose from './Quiz/purpose';
import Who from './Quiz/who';

const LandingWrapper = styled.div`
  height: 100vh;
  min-height: 733px;
  position: relative;

  .start_fade {
    opacity: 0;
  }

  @media (min-width: 768px) {
    min-height: 1024px;
  }

  @media (min-width: 1024px) {
    min-height: 46.25rem;
  }

  /* Extra classes for question styling */
  .tablet_purpose {
    @media (min-width: 768px) {
      .question_width {
        width: 41.75rem;
      }
    }
  }

  .add_mexico_styles {
    @media (max-width: 768px) {
      margin-top: 2px !important;
    }
  }
`;

const InnerLanding = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: left;
  display: flex;
  justify-content: center;
  align-items: center;

  .back {
    height: 100%;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
  }

  .hoverButton {
    transition: background-color 0.25s ease-in-out;
    border: 0px;

    :hover {
      background-color: #ffd100;
    }
  }
`;

const LandingFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: -130px;
  position: relative;
  z-index: 10;

  @media (min-width: 768px) {
    margin-top: 0px;
    transform: translateY(-17%);
  }

  @media (min-width: 1024px) {
    transform: translateY(-14%) !important;
  }
`;

const Counter = styled.div`
  color: #ffffff;
  font-family: 'Trend Slab';
  font-size: 10px;
  line-height: 13px;
  margin: 32px 0px 15px;

  @media (min-width: 768px) {
    margin: 116px 0px 28px;
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }

  @media (min-width: 1024px) {
    margin: 3.375rem 0px 2.625rem;
  }
`;

const Question = styled.h1`
  color: #ffffff;
  font-family: 'Trend Slab';
  font-size: 22px;
  line-height: 28px;
  opacity: 0;
  padding: 0px;
  width: 280px;

  @media (min-width: 768px) {
    padding: 0px;
    font-size: 2.25rem;
    line-height: 2.6875rem;
    width: 29.5rem;
  }
`;

const Divider = styled.div`
  height: 0.1875rem;
  width: 2.5rem;
  border-radius: 0.09375rem;
  background-color: #ffd100;
  margin: 15px 0px 30px;

  @media (min-width: 768px) {
    margin: 0.6875rem 0px 3.75rem;
  }
`;

const AnswerContainer = styled.div``;

const LandingIndex = ({ lang, cms }) => {
  const { updateState } = useContext(StateContext);
  const [loader, setLoader] = useState(true);
  const [currentStage, setCurrentStage] = useState(1);

  /* CMS Questions */
  const exploreAnswer = cms.question_explore.text;
  const purposeAnswer = cms.question_purpose.text;
  const whoAnswer = cms.question_who.text;
  /* CMS Answers */
  const adventure = cms.option_adventure.text;
  const culture = cms.option_culture.text;
  const relaxation = cms.option_relaxation.text;
  const education = cms.option_education.text;
  const exploration = cms.option_exploration.text;
  const elevation = cms.option_elevation.text;
  const family = cms.option_family.text;
  const friends = cms.option_friends.text;
  const solo = cms.option_solo.text;

  /* Questions State */
  const [questions, setQuestions] = useState([
    {
      id: 1,
      stage: 1,
      question: exploreAnswer,
      answers: [
        { answer_id: 1, text: adventure },
        { answer_id: 2, text: culture },
      ],
    },
    {
      id: 2,
      stage: 2,
      question: purposeAnswer,
      answers: [
        { answer_id: 1, text: relaxation },
        { answer_id: 2, text: education },
        { answer_id: 3, text: exploration },
        { answer_id: 4, text: elevation },
      ],
    },
    {
      id: 3,
      stage: 3,
      question: whoAnswer,
      answers: [
        { answer_id: 1, text: family },
        { answer_id: 2, text: friends },
        { answer_id: 3, text: solo },
      ],
    },
  ]);

  const [currentQuestion, setCurrentQuestion] = useState(questions[currentStage - 1]);

  const textAnimation = () => {
    /* Text Anim Timeline */
    const textTimeline = new TimelineMax({});
    const mySplitText = new SplitText('.text_anim', { type: 'words, lines, chars' });
    const { chars } = mySplitText;
    textTimeline
      .set('.text_anim', { opacity: 1 })
      .staggerFrom(
        chars,
        0.2,
        {
          opacity: 0,
          y: '0.0625rem',
          ease: Power1.easeIn,
        },
        0.05,
      )
      .set('.stagger', { css: { visibility: 'visible' } })
      .staggerTo('.stagger', 0.4, { y: 0, ease: Power1.easeInOut, opacity: 1 }, 0.1);
  };

  const chooseOption = event => {
    const value = event.currentTarget.id;

    const landing = document.querySelector('.landing_translate');

    if (currentStage === 1) {
      updateState({ first: value });

      const tl = new TimelineMax({
        onComplete: () => {
          /* Set timeout to avoid safari glitch */
          setTimeout(() => {
            setCurrentStage(2);

            /* Add unique styling for purpose stage */
            landing.classList.add('tablet_purpose');
            textAnimation();
          }, 200);
        },
      });

      /* Animate background */
      tl.to('.hide', 0.3, {
        opacity: 0,
        ease: Sine.easeInOut,
      })
        .to('.second', 0.6, {
          opacity: 1,
          delay: 0.2,
          ease: Sine.easeOut,
        })
        .set('.first', { css: { display: 'none' } });
    } else if (currentStage === 2) {
      updateState({ second: value });

      const tl2 = new TimelineMax({
        onComplete: () => {
          /* Set timeout to avoid safari glitch */
          setTimeout(() => {
            setCurrentStage(3);

            /* Remove unique styling for purpose stage */
            landing.classList.remove('tablet_purpose');
            textAnimation();
          }, 200);
        },
      });

      tl2
        .to('.hide', 0.3, {
          opacity: 0,
          ease: Sine.easeInOut,
        })
        .set('.third', { css: { display: 'block' } })
        .to('.third', 0.6, { opacity: 1, delay: 0.2 })
        .set('.second', { css: { display: 'none' } });
    } else {
      updateState({ third: value });
      navigate(`/${lang}/connect`);
    }
  };

  useEffect(() => {
    /* Set Stagger for Select Option animation */
    const tl = new TimelineMax();
    tl.set('.stagger', { y: '2.1875rem', opacity: 0 });

    if (currentStage !== 1) {
      setCurrentQuestion(questions[currentStage - 1]);
      tl.set('.stagger', { y: '2.1875rem', opacity: 0 });
    }
  }, [currentStage]);

  useEffect(() => {
    /* Animations once loader completed */
    if (!loader) {
      TweenMax.to('.start_fade', 0.5, {
        ease: Sine.easeInOut,
        opacity: 1,
        // onComplete: textAnimation,
      });

      textAnimation();
    }
  }, [loader]);

  useEffect(() => {
    /* Add unique styling for mexico, then remove once stage passed */
    if (lang === 'es-mx' && cms && currentStage === 2) {
      document.querySelector('.counter').classList.add('add_mexico_styles');
    } else {
      document.querySelector('.counter').classList.remove('add_mexico_styles');
    }
  }, [currentStage]);

  return (
    <LandingWrapper>
      {loader && <Loader setLoader={setLoader} />}
      <InnerLanding>
        <Background />
        <LandingFlex className="landing_translate">
          <Header />
          <Counter className="start_fade counter">{`0${currentStage}/03`}</Counter>
          <Question className="question_width text_anim hide">{currentQuestion.question}</Question>
          <Divider />
          <AnswerContainer>
            {currentStage === 1 ? (
              <Explore questions={questions[0]} chooseOption={chooseOption} />
            ) : currentStage === 2 ? (
              <Purpose questions={questions[1]} chooseOption={chooseOption} />
            ) : (
              <Who questions={questions[2]} chooseOption={chooseOption} />
            )}
          </AnswerContainer>
        </LandingFlex>
      </InnerLanding>
      <Footer />
    </LandingWrapper>
  );
};

export default LandingIndex;
