import React from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';

/* SVG */
import family from '../../../images/svgs/quiz/family.svg';
import friends from '../../../images/svgs/quiz/friends.svg';
import solo from '../../../images/svgs/quiz/solo.svg';

const WhoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  * .stagger {
    opacity: 0;
  }
`;

const WhoFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 300px;

  @media (min-width: 1024px) {
    width: 56.25rem;
  }
`;

const Answer = styled.div`
  height: 7.5rem;
  width: 8.125rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0.625rem 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.2);
  margin: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.large_answer {
    height: 7.5rem;
    width: 17.5rem;
    margin: 0px;
  }

  @media (min-width: 1024px) {
    height: 7.5rem;
    width: 17.5rem;
    margin: 0px 0.625rem 0px !important;
  }
`;

const AnswerText = styled.h2`
  text-transform: uppercase;
  color: #3d4643;
  font-family: 'Trend Sans';
  font-size: 0.875rem;
  letter-spacing: -0.03125rem;
  line-height: 1.125rem;
`;

const Icon = styled(Svg)`
  &._family {
    height: 2.625rem;
    width: 2.75rem;
    margin-bottom: 1.0625rem;
  }

  &._friends {
    height: 2.625rem;
    width: 2.375rem;
    margin-bottom: 1.125rem;
  }

  &._solo {
    height: 2.625rem;
    width: 1rem;
    margin-bottom: 1.125rem;
  }
`;

const Who = ({ questions, chooseOption }) => (
  <WhoWrapper>
    <WhoFlex>
      <Answer
        className="large_answer stagger hoverButton"
        onClick={chooseOption}
        id={questions.answers[0].answer_id}
        data-event-label="family option"
      >
        <div>
          <Icon src={family} className="_family" />
          <AnswerText>{questions.answers[0].text}</AnswerText>
        </div>
      </Answer>
      <Answer
        className="stagger hoverButton"
        onClick={chooseOption}
        id={questions.answers[1].answer_id}
        data-event-label="friends option"
      >
        <div>
          <Icon src={friends} className="_friends" />
          <AnswerText>{questions.answers[1].text}</AnswerText>
        </div>
      </Answer>
      <Answer
        className="stagger hoverButton"
        onClick={chooseOption}
        id={questions.answers[2].answer_id}
        data-event-label="solo option"
      >
        <div>
          <Icon src={solo} className="_solo" />
          <AnswerText>{questions.answers[2].text}</AnswerText>
        </div>
      </Answer>
    </WhoFlex>
  </WhoWrapper>
);

export default Who;
