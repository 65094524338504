import React from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';

/* SVG */
import relax from '../../../images/svgs/quiz/relaxation.svg';
import education from '../../../images/svgs/quiz/education.svg';
import explore from '../../../images/svgs/quiz/exploration.svg';
import elevate from '../../../images/svgs/quiz/elevation.svg';

const PurposeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
`;

const PurposeFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 305px;

  @media (min-width: 1024px) {
    width: 37.8125rem;
  }
`;

const Answer = styled.div`
  height: 7.5rem;
  width: 8.125rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0.625rem 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.2);
  margin: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  @media (min-width: 1024px) {
    margin: 0px 0.625rem;
  }
`;

const AnswerText = styled.h2`
  text-transform: uppercase;
  color: #3d4643;
  font-family: 'Trend Sans';
  font-size: 0.875rem;
  letter-spacing: -0.03125rem;
  line-height: 1.125rem;
`;

const IconRelax = styled(Svg)`
  height: 2.5rem;
  width: 1.4375rem;
  margin-bottom: 1.1875rem;
`;

const IconEdu = styled(Svg)`
  height: 2.5625rem;
  width: 1.9375rem;
  margin-bottom: 1.125rem;
`;

const IconExp = styled(Svg)`
  height: 2.5rem;
  width: 2.4375rem;
  margin-bottom: 1.0625rem;
`;

const IconElev = styled(Svg)`
  width: 3.1875rem;
  height: 2.625rem;
  margin-bottom: 1.125rem;
`;

const Purpose = ({ questions, chooseOption }) => (
  <PurposeWrapper className="hide">
    <PurposeFlex>
      <Answer
        onClick={chooseOption}
        className="stagger hoverButton"
        id={questions.answers[0].answer_id}
        data-event-label="relax option"
      >
        <div>
          <IconRelax src={relax} />
          <AnswerText>{questions.answers[0].text}</AnswerText>
        </div>
      </Answer>
      <Answer
        onClick={chooseOption}
        className="stagger hoverButton"
        id={questions.answers[1].answer_id}
        data-event-label="education option"
      >
        <div>
          <IconEdu src={education} />
          <AnswerText>{questions.answers[1].text}</AnswerText>
        </div>
      </Answer>
      <Answer
        onClick={chooseOption}
        className="stagger hoverButton"
        id={questions.answers[2].answer_id}
        data-event-label="explore option"
      >
        <div>
          <IconExp src={explore} />
          <AnswerText>{questions.answers[2].text}</AnswerText>
        </div>
      </Answer>
      <Answer
        onClick={chooseOption}
        className="stagger hoverButton"
        id={questions.answers[3].answer_id}
        data-event-label="elevation option"
      >
        <div>
          <IconElev src={elevate} />
          <AnswerText>{questions.answers[3].text}</AnswerText>
        </div>
      </Answer>
    </PurposeFlex>
  </PurposeWrapper>
);

export default Purpose;
