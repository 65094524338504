import React, { useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Cookies from 'js-cookie';
import { StateContext } from '../../utils/globalState';

import Layout from '../layout';
import SEO from '../seo';
import LandingIndex from '../Landing/index';

const Index = ({
  pageContext,
  data: {
    prismicLanding: { data },
  },
}) => {
  const { updateState } = useContext(StateContext);

  useEffect(() => {
    updateState({
      first: null,
      second: null,
      third: null,
      journey: null,
    });

    Cookies.remove('journey');
    Cookies.remove('first');
    Cookies.remove('second');
    Cookies.remove('third');
  }, []);

  return (
    <Layout>
      <SEO
        title="Visit California"
        pageUrl="/"
        description="Visit California"
        twitterUsername="@VisitCA"
        lang={pageContext.territory}
      />
      {data && <LandingIndex lang={pageContext.territory} cms={data} />}
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicLanding(lang: { eq: $lang }) {
      data {
        question_explore {
          text
        }
        question_purpose {
          text
        }
        question_who {
          text
        }
        option_adventure {
          text
        }
        option_culture {
          text
        }
        option_relaxation {
          text
        }
        option_education {
          text
        }
        option_exploration {
          text
        }
        option_elevation {
          text
        }
        option_family {
          text
        }
        option_friends {
          text
        }
        option_solo {
          text
        }
      }
    }
  }
`;

export default Index;
