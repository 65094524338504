import React from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';

/* SVG */
import adventureSvg from '../../../images/svgs/quiz/adventure.svg';
import cultureSvg from '../../../images/svgs/quiz/culture.svg';

const ExploreWrapper = styled.div`
  @media (min-width: 1024px) {
    width: 36.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Answer = styled.div`
  height: 7.5rem;
  width: 17.5rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0.625rem 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: hidden;

  @media (min-width: 1024px) {
    margin: 0px 0.625rem;
  }

  :nth-child(2) {
    margin: 20px 0px;

    @media (min-width: 1024px) {
      margin: 0px 0.625rem;
    }
  }
`;

const Adventure = styled(Svg)`
  height: 2.625rem;
  width: 4.1875rem;
  margin-bottom: 0.9375rem;
`;

const Culture = styled(Svg)`
  height: 2.625rem;
  width: 2.6875rem;
  margin-bottom: 1.125rem;
`;

const AnswerText = styled.h2`
  text-transform: uppercase;
  color: #3d4643;
  font-family: 'Trend Sans';
  font-size: 0.875rem;
  letter-spacing: -0.03125rem;
  line-height: 1.125rem;
`;

const Explore = ({ questions, chooseOption }) => (
  <ExploreWrapper className="hide">
    <Answer
      onClick={chooseOption}
      className="stagger hoverButton"
      id={questions.answers[0].answer_id}
      data-event-label="adventure option"
    >
      <div>
        <Adventure src={adventureSvg} />
        <AnswerText>{questions.answers[0].text}</AnswerText>
      </div>
    </Answer>
    <Answer
      onClick={chooseOption}
      className="stagger hoverButton"
      id={questions.answers[1].answer_id}
      data-event-label="culture option"
    >
      <div>
        <Culture src={cultureSvg} />
        <AnswerText>{questions.answers[1].text}</AnswerText>
      </div>
    </Answer>
  </ExploreWrapper>
);

export default Explore;
