import React from 'react';
import styled from 'styled-components';

/* Mobile */
import oneMob from '../../../images/background/home_01_mobile.jpg';
import twoMob from '../../../images/background/home_02_mobile.jpg';
import threeMob from '../../../images/background/home_03_mobile.jpg';

/* Tablet */
import oneTab from '../../../images/background/home_01_tablet.jpg';
import twoTab from '../../../images/background/home_02_tablet.jpg';
import threeTab from '../../../images/background/home_03_tablet.jpg';

/* Desktop */
import oneDesk from '../../../images/background/home_01_desktop.jpg';
import twoDesk from '../../../images/background/home_02_desktop.jpg';
import threeDesk from '../../../images/background/home_03_desktop.jpg';

const ExploreBackground = styled.div`
  background: url(${oneMob});
  background-size: cover;
  background-position: left;

  @media (min-width: 768px) {
    background: url(${oneTab});
    background-size: cover;
    background-position: left;
  }

  @media (min-width: 1024px) {
    background: url(${oneDesk});
    background-size: cover;
    background-position: left;
  }
`;

const PurposeBackground = styled.div`
  background: url(${twoMob});
  background-size: cover;
  background-position: left;
  opacity: 0;

  @media (min-width: 768px) {
    background: url(${twoTab});
    background-size: cover;
    background-position: left;
  }

  @media (min-width: 1024px) {
    background: url(${twoDesk});
    background-size: cover;
    background-position: left;
  }
`;

const WhoBackground = styled.div`
  background: url(${threeMob});
  background-size: cover;
  background-position: left;
  display: none;
  opacity: 0;

  @media (min-width: 768px) {
    background: url(${threeTab});
    background-size: cover;
    background-position: left;
  }

  @media (min-width: 1024px) {
    background: url(${threeDesk});
    background-size: cover;
    background-position: left;
  }
`;

const Background = () => (
  <React.Fragment>
    <ExploreBackground className="first back" />
    <PurposeBackground className="second back" />
    <WhoBackground className="third back" />
  </React.Fragment>
);

export default Background;
